.drawer {
    background: rgb(54,52,70);
    background: linear-gradient(90deg, rgba(54,52,70,1) 0%, rgba(37,36,48,1) 100%);
    width: 3.5rem;
    transition: width 0.2s;
    padding: 0.5rem;
    z-index: 7;
    display: flex;
    position: absolute;
    height: 100%;
}

.drawer-logo-container {
    width: 40px;
    position: absolute;
    z-index: 10;
    padding: 6px;
}

.drawer-menu-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex: 1;
    padding-top: 48px;
    z-index: 10;
}

.drawer-menu-list {
    color: rgba(0, 0, 0, 0.87);
}

.drawer-menu-list-item {
    font-size: 24px;
    background-color: rgba(255,255,255, 0.75);
    font-size: 24px;
    padding: 7px;
    line-height: 0;
    width: 100%;
    background-color: transparent;
    border-radius: 6px;
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.8);
    font-size: 1.5rem;
    margin: 0.5rem auto;
    cursor: pointer;
    transition: color 0.2s;
    transition: background-color 0.2s;
}

.drawer-menu-list-item-focus {
    background-color: #ffffff38;
    transition: background-color 0.2s;
}

.drawer-menu-list-item:hover {
    color: white;
    background-color: #ffffff38;
    transition: background-color 0.2s;
    transition: color 0.2s;
}

.drawer-menu-list-item-label {
    font-size: .875rem;
    margin: 12px;
    display: none;
}
.drawer-header-overlay {
    background: linear-gradient(137.216deg, rgba(66, 64, 83, 70%) 0%, rgba(53, 51, 67, 86%) 67%, #292735 100%);
    height: 3.5rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 3.5rem;
    z-index: 9;
    transition: width 0.2s;
}

.drawer-bg-image {
    opacity: 1;
    width: 3.5rem;
    height: 3.5rem;
    transition: width 0.2s;
    z-index: 8;
}

.drawer-menu-divider {
    height: 1px;
    color: rgba(255, 255, 255, 0.1);
    margin: 0.5rem 0;
}

.drawer:hover {
    width: 13rem;
    transition: width 0.2s;
}

.drawer:hover .drawer-menu-list-item-label {
    display: block;
}


.drawer:hover .drawer-header-overlay {
    height: 13rem;
    width: 13rem;
    transition: width 0.2s;
}

.drawer:hover .drawer-bg-image {
    width: 13rem;
    height: 13rem;
    transition: width 0.2s;
}



.image-container {
    position: absolute;
    margin: 0;
    top: 0;
    left: 0;
}

.image-container img {
    height: 3.5rem;
}

.image-container::after {
    content: "";
    position: absolute;
    bottom: 6px;
    
    left: 0;
    right: 0;
    height: 100%; /* Adjust the height as needed */
    background: linear-gradient(to bottom, rgba(66, 64, 83, 70%) 0%, #292735 100%);
    pointer-events: none; /* Ensure the overlay doesn't interfere with interactions */
}

/* .main {
    background: rgb(0,63,92);
    background: -moz-linear-gradient(114deg, rgba(0,63,92,0.20910370984331228) 9%, rgba(60,166,125,0.11666673505339631) 43%, rgba(163,206,241,0.20630258939513302) 91%);
    background: -webkit-linear-gradient(114deg, rgba(0,63,92,0.20910370984331228) 9%, rgba(60,166,125,0.11666673505339631) 43%, rgba(163,206,241,0.20630258939513302) 91%);
    background: linear-gradient(114deg, rgba(0,63,92,0.20910370984331228) 9%, rgba(60,166,125,0.11666673505339631) 43%, rgba(163,206,241,0.20630258939513302) 91%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#003f5c",endColorstr="#a3cef1",GradientType=1); 
} */