.ListItemInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;
    transition: all 0.3s ease;
    flex: 1;
}

.ListItemInner:hover {
    background-color: #f0f0f0;
    transition: all 0.3s ease;
}